import { Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, InputAdornment, Slider, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import { ToastContainer } from "react-toastify";
import Color from "../../../assets/colors";
import Lottie from "react-lottie-player";

export const StyledDialog = styled(Dialog)({

  '& .MuiDialog-paper': {
    display: "flex",
    paddingTop: "16px",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "24px",
    border: "1.5px solid var(--Primary-Black-One, #222)",
    background: "var(--Primary-White, #FFF)",
    width: "100%",
    height: "100%",
    maxWidth: "722px",
    maxHeight: "680px",

    '@media(max-width: 768px)': {
      maxWidth: "330px",
      maxHeight: "600px",
    }
  },
})

export const StyledDialogContent = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  width: "100%",
  maxHeight: "590px",
  height: "100%",
  '@media(max-width: 768px)': {
    gap: "8px",
    maxHeight: "522px",
  }
})

export const StyledContentTop = styled('div')({ //DialogTitle will make the Title disappear in Job Alert Page

  display: "flex",
  width: "100%",
  maxWidth: "720px",
  // height: "100%",
  maxHeight: "66px",
  padding: "0px 24px 16px 24px",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  borderBottom: "1px solid var(--Grey-Grey, #D8D8D8)",

  '@media(max-width: 768px)': {
    maxWidth: "328px",
    padding: "0px 16px 8px 16px",
    alignSelf: "stretch",
  }
})

export const TitleContainer = styled('div')({

  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignSelf: "stretch",
  width: "100%",
})

export const TitleContent = styled('div')({

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "2px",
  flex: "1 0 0",
  alignSelf: "stretch",
  width: "100%",
  '@media(max-width: 768px)': {
    maxHeight: "44px",
    maxWidth: "272px",
  }
})

export const CloseIcon = styled(XMarkIcon)({

  width: "24px",
  height: "24px",
  "&:hover":{
    cursor: "pointer",
  }
})

export const BodyContainer = styled('div')({

  display: "flex",
  padding: "0px 16px 24px 24px",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  marginRight: "16px",
  overflowY: "scroll",
  '@media(max-width: 768px)': {
    maxHeight: "482px",
    maxWidth: "328px",
    padding: "0px 8px 16px 16px",
    marginRight: "8px",
  },

  '& ::-webkit-scrollbar': {
    width: "4px",
    position: "fixed",
  },
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    width: "4px",
    overflow: "auto",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    backgroundColor: "#512ACC",
    overflow: "auto",
    borderRadius: "100px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#D8D8D8",
    width: "20%",
    marginBottom: "24px",
    borderRadius: "100px",
  },
  "&::-webkit-scrollbar-rail": {
    backgroundColor: "#512ACC",
    borderRadius: "100px",
  },
})

export const BodyContent = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  flex: "1 0 0",
  flexWrap: "wrap",

  '@media(max-width: 768px)': {
    maxWidth: "292px",
    gap: "24px",
  }
})

export const TextFieldContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  width: "100%",
  maxWidth: "660px",

  '@media(max-width: 768px)': {
    maxWidth: "292px",
    gap: "8px",
  }
})

export const StyledTextField = styled(TextField)(({isError}) => ({

  position: "relative",
  width: "100%",
  '& .MuiInputBase-root': {
    padding: 0,
    "& .MuiOutlinedInput-input":{
      height: "30px",
      display: "flex",
      gap: "10px",
      alignSelf: "stretch",
      borderRadius: "8px",
      border: !isError ? "1px solid #D8D8D8" : "1px solid #F75443",
      background: "var(--Primary-White, #FFF)",
      padding: "8px 16px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  '& fieldset': {
    border: 'none',
  },
  '& .MuiFormHelperText-root':{
    margin: 0,
    padding: "0 16px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.112px",
  },
  '@media(max-width: 768px)': {
    maxWidth: "292px",
    gap: "8px"
  },
  
}))

export const ErrorIconContainer = styled(InputAdornment)({

  '&&':{

    position: "absolute",
    left: "94%",
    margin: 0,

    '@media(max-width: 768px)': {
      left: "86%",
    },
  },
})

export const DropdownListContainer = styled(Box)({

  zIndex: "1",
  maxHeight: "16rem",
  width: "100%",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  position: "relative",
  overflow: "auto",
  boxShadow: "0.25rem 0.25rem 1.25rem rgba(0, 0, 0, 0.16)",
  maxWidth: "660px",
  '@media(max-width: 768px)': {
    maxWidth: "292px",
  }
});

export const DropdownItem = styled(Box)({

  width: "100%",
  padding: "0.625rem 1rem",
  cursor: "pointer",

  ":hover": {
    background: "lightgrey",
  },
});

export const DropdownText = styled("span")({
  color: "black",
  fontSize: "14px",
});

export const AlertContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  '@media(max-width: 768px)': {
    maxWidth: "292px",
    gap: "8px",
  }
})

export const AlertContent = styled('div')({

  display: "flex",
  alignItems: 'center',
  gap: "40px",
  alignSelf: "stretch",
  '@media(max-width: 768px)': {
    maxWidth: "292px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",

  }
})

export const FrequencyContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
  '@media(max-width: 768px)': {
    maxWidth: "292px",
  }
})

export const AlertViaContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
  flex: "1 0 0",
  '@media(max-width: 768px)': {
    maxWidth: "292px",
    gap: "12px",
  }
})

export const ButtonGroup = styled('div')({
  display: "flex",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  '@media(max-width: 768px)': {
    maxHeight: "36px",
    gap: "4px",
  }
})

export const StyledButton = styled('button')(({ active }) => ({

  display: "flex",
  padding: "8px 14px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "100px",
  whiteSpace: "nowrap",
  ...(active ? {
    background: "#512ACC",
    border: "1px solid #000000",
  } : {
    background: "#FFFFFF",
    border: "1px solid #D8D8D8",
  }),
  "&:hover":{
    border: "1px solid #000000",
    cursor: "pointer",
  },
}))

export const ButtonText = styled(Typography)(({ active, isMobile }) => ({

  ...(active ? { color: "#FFFFFF" } : { color: "black" }),

  fontFamily: "Inter",
  fontSize: !isMobile ? "16px !important" : "14px !important",
  fontStyle: "normal !important",
  fontWeight: "400 !important",
  lineHeight: !isMobile ? "24px !important" : "20px !important",
  letterSpacing: !isMobile ? "0.15px !important" : "0.112px !important",
  textAlign: "center",
}))

export const AdditionalFilterContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
  maxWidth: "660px",
  width: "100%",
  height: "100%",
  '@media(max-width: 768px)': {
    maxWidth: "292px",
    gap: "8px",
  }
})

export const YOEContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
  alignSelf: "stretch",
  width: "100%",
  height: "100%",
})

export const CheckboxGroup = styled(FormGroup)({

  display: "flex",
  flexDirection: "row !important",
  alignItems: "flex-start",
  alignContent: "flex-start",
  gap: "16px 0px",
  alignSelf: "stretch",
  flexWrap: "wrap",
  '@media(max-width: 768px)': {
    '& > span': {
      maxWidth: "292px",
      width: "100%",
    },
    flexDirection: "column",
    gap: "16px",
  }
})

export const CheckboxContainer = styled('span')({

  display: "flex",
  width: "50%",
  gap: "8px",
  '@media(max-width: 768px)': {
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  }
})

export const CheckedIcon = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: "1px solid var(--Grey-Secondary-Grey, #AEAEAE)",
  backgroundColor: "#222222",
});

export const CheckStyled = styled(CheckIcon)({

  width: "12px",
  height: "12px",
  color: "#FFFFFF",
});

export const UncheckedIcon = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: "1px solid var(--Grey-Secondary-Grey, #AEAEAE)",
  backgroundColor: "transparent",
});

export const UncheckStyled = styled(CheckIcon)({
  width: "12px",
  height: "12px",
  color: "#222222",
});

export const StyledFormControlLabel = styled(FormControlLabel)(({ isMobile }) => ({

  display: "flex",
  maxWidth: "292px",
  width: "100%",
  margin: 0,

  "& .MuiTypography-root": {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Inter",
    fontSize: !isMobile ? "14px" : "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: !isMobile ? "24px" : "20px",
    textAlign: "center",
  },

  '& .MuiButtonBase-root': {
    '@media(max-width: 768px)': {
      padding: 0,
    }
  },

  '@media(max-width: 768px)': {
    justifyContent: "space-between",
    "&&":{
      margin: 0,
    },
  }
}))

export const StyledCheckbox = styled(Checkbox)(({ isMobile }) => ({

  padding: "9px 9px 9px 0px",

  "& .MuiTypography-root": {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Inter",
    fontSize: !isMobile ? "14px" : "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: !isMobile ? "24px" : "20px",
    textAlign: "center",
  },
}))

export const SalaryContainer = styled('div')({

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
  alignSelf: "stretch",
  width: "100%",
  height: "100%",
})

export const SliderContainer = styled('div')({

  display: "flex",
  padding: "0 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",

  '@media(max-width: 768px)': {
    padding: "0 8px",
  }
})

export const StyledSlider = styled(Slider)({
  maxWidth: "616px",
  width: "100%",
  '@media(max-width: 768px)': {
    maxWidth: "264px",
  },
  '& .MuiSlider-thumb': {
    position: "absolute",
    marginLeft: "13.5px",
    border: "#512ACC 1px solid",
    color: "#FFFFFF",
    maxWidth: "27px",
    maxHeight: "27px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  '& .MuiSlider-rail': {
    color: "var(--Grey-Grey, #D8D8D8)",
    opacity: 1,
    marginLeft: "13.5px",
    maxWidth: "616px",
    '@media(max-width: 768px)': {
      maxWidth: "264px",
    }
  },

  '& .MuiSlider-track': {
    marginLeft: "13.5px",
    maxWidth: "616px",
    '@media(max-width: 768px)': {
      maxWidth: "264px",
    }
  },

})

export const ButtonContainer = styled('div')({

  display: "flex",
  padding: "16px 30px",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  borderTop: "1px solid var(--Grey-Grey, #D8D8D8)",
  background: "var(--Primary-White, #FFF)",
  width: "100%",
  height: "100%",
  maxHeight: "74px",
  '@media(max-width: 768px)': {
    padding: "16px",
  },
})

export const CreateAlertButton = styled('button')({ //MUI Button will make the Button disappear after refreshing the web page so replace it will button

  display: "flex",
  maxWidth: "200px",
  width: "100%",
  maxHeight: "42px",
  height: "100%",
  padding: "0px 32px",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  borderRadius: "100px",
  background: "#353535",
  color: "#FFFFFF",
  textTransform: "none",

  '&:hover': {
    background: "#353535",
    cursor: "pointer",
  },

  '@media(max-width: 768px)': {
    maxWidth: "296px",
    width: "100%"
  },
})

export const CreateAlertButtonText = styled(Typography)(({ isMobile }) => ({

  flex: "1 0 0",
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Inter !important",
  fontSize: !isMobile ? "16px !important" : "12px !important",
  fontStyle: "normal !important",
  fontWeight: "700 !important",
  lineHeight: "normal !important",
  letterSpacing: isMobile && "0.055px !important",
}))

export const TopContentTitle = styled(Typography)(({ isMobile }) => ({

  fontFamily: "Inter !important",
  fontSize: !isMobile ? "16px !important" : "12px !important",
  fontStyle: "normal !important",
  fontWeight: "700 !important",
  lineHeight: !isMobile ? "24px !important" : "22px !important",
  alignSelf: "stretch",
}))

export const TopContentSubtitle = styled(Typography)(({ isMobile }) => ({

  fontFamily: "Inter !important",
  fontSize: !isMobile ? "16px !important" : "12px !important",
  fontStyle: "normal !important",
  fontWeight: "400 !important",
  lineHeight: !isMobile ? "24px !important" : "20px !important",
  alignSelf: "stretch",
}))

export const BodyContentTitle = styled(Typography)(({ isMobile }) => ({

  alignSelf: "stretch",
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Inter !important",
  fontSize: !isMobile ? "20px !important" : "17px !important",
  fontStyle: "normal !important",
  fontWeight: "500 !important",
  lineHeight: "24px !important",
}))

export const BodyContentSubtitle = styled(Typography)(({ isMobile }) => ({

  alignSelf: "stretch",
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Inter !important",
  fontSize: !isMobile ? "16px !important" : "14px !important",
  fontStyle: "normal !important",
  fontWeight: "500 !important",
  lineHeight: !isMobile ? "24px !important" : "20px !important",
  letterSpacing: !isMobile ? "0.15px !important" : "0.112px !important",
}))

//--------------------------------------------------------|Visitor Created Job Alert|

export const VisitorButton = styled(Button)(({side}) => ({

  display: "flex",
  width: "200px",
  maxHeight: "42px",
  height: "100%",
  padding: "0px 32px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "100px",
  border: side === "left" && "1px solid #222",
  background: side === "left" ? "#FFF" : "#512ACC",
  textTransform: "none",
  "&:hover":{
    cursor: "pointer",
    background: side === "left" ? "#EFEFEF" : "#512ACC",
  },
}))

export const VisitorButtonText = styled(Typography)(({ isMobile, side }) => ({

  color: side === "left" ? "#222" : "#FFF",
  textAlign: "center",
  fontFamily: "Inter !important",
  fontSize: !isMobile ? "16px !important" : "12px !important",
  fontStyle: "normal !important",
  fontWeight: "500 !important",
  lineHeight: "normal !important",
  letterSpacing: !isMobile ? "0.074px !important" : "0.055px !important",
}))

export const VisitorCreatedBodyContainer = styled(DialogContent)({

  display: "flex",
  padding: "0px 24px 24px 24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  flex: "1 0 0",
  maxHeight: "534px",
  maxWidth: "720px",
  width: "100%",
  '@media(max-width: 768px)': {
    maxWidth: "328px",
    padding: "0px 16px 16px 16px",
    gap: "16px",
  }
})

export const CreatedBodyContentText = styled(Typography)(({isMobile}) => ({

  alignSelf: "stretch",
  color: "rgba(0, 0, 0, 0.87)",
  textAlign: "center",
  fontFamily: "Inter !important",
  fontSize: isMobile ? "16px !important" : "14px !important",
  fontStyle: "normal !important",
  fontWeight: "400 !important",
  lineHeight: "20px !important",
  letterSpacing: "0.112px !important",
}))

export const CreatedBodyContentSubtitleContainer = styled('span')({

  display: "flex",
  padding: "10px 24px",
  alignItems: "flex-start",
  gap: "10px",
  borderRadius: "10px",
  background: "#EFEFFD",
})

export const CreatedBodyContentSubtitleText = styled(Typography)(({bold}) => ({

  color: "rgba(0, 0, 0, 0.87)",
  textAlign: "center",
  fontFamily: "Inter !important",
  fontSize: "14px !important",
  fontStyle: "normal !important",
  fontWeight: bold ? "700 !important" : "400 !important",
  lineHeight: "24px !important",
  letterSpacing: bold ? "0.1px !important" : "0.15px !important",
}))

export const TextContainer = styled('div')({

  textAlign: "center",
})

export const StyledErrorIcon = styled(ExclamationCircleIcon)({

  position: "absolute",
  width: "19.683px",
  height: "20px",
  color: "#F75443",
})

export const ToastStyled = styled(ToastContainer)({
  "&.Toastify__toast-container": {
    width: "15.625rem",
    padding: "0.625rem 0.5rem 0.5rem 1rem",
  },

  "& .Toastify__toast": {
    color: Color.success,
    border: `1px solid ${Color.success}`,
    borderRadius: "0.5rem",

    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.25rem",

    // width: "15.625rem",
    // "0.625rem 0.5rem 0.5rem 1rem",

    "--toastify-color-progress-light": Color.success,
  },
});

export const StyledLine = styled("div")({

  height: "0.8px",
  width: "100%",
  borderBottom: "0.8px solid #D8D8D8",
})