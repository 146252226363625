import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import _, { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLightCastTokenTimeOut } from "../../../helpers/auth";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateJobAlertCreateDialog,
  updateJobAlertCreateDialogWithKeyword,
  updateJobAlertsDownloadAppDialog,
  updateVisitorJobAlertDialogDetails,
} from "../../../redux/actions/dialog_action";
import {
  createJobAlert,
  getExperienceLevels,
  updateShowJobAlertDialog,
  visitorAddJobAlert,
} from "../../../redux/actions/job_action";
import {
  fetchingJobList,
  updateJobTitleSuggestionsDropdownStatus,
} from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/job_type";
import {
  AdditionalFilterContainer,
  AlertContainer,
  AlertContent,
  AlertViaContainer,
  BodyContainer,
  BodyContent,
  BodyContentSubtitle,
  BodyContentTitle,
  ButtonContainer,
  ButtonGroup,
  ButtonText,
  CheckboxContainer,
  CheckboxGroup,
  CheckedIcon,
  CheckStyled,
  CloseIcon,
  CreateAlertButton,
  CreateAlertButtonText,
  CreatedBodyContentSubtitleContainer,
  CreatedBodyContentSubtitleText,
  CreatedBodyContentText,
  DropdownItem,
  DropdownListContainer,
  DropdownText,
  ErrorIconContainer,
  FrequencyContainer,
  SalaryContainer,
  SliderContainer,
  StyledButton,
  StyledCheckbox,
  StyledContentTop,
  StyledDialog,
  StyledDialogContent,
  StyledErrorIcon,
  StyledFormControlLabel,
  StyledLine,
  StyledLottie,
  StyledSlider,
  StyledTextField,
  TextContainer,
  TextFieldContainer,
  TitleContainer,
  TitleContent,
  TopContentSubtitle,
  TopContentTitle,
  UncheckedIcon,
  UncheckStyled,
  VisitorButton,
  VisitorButtonText,
  VisitorCreatedBodyContainer,
  YOEContainer,
} from "./styles";
import { updateShowSignInModalStatus, updateSignInModalSignUpStatus, updateSignUpOrigin } from "../../../redux/actions/navbar_action";
import DoneGif from "../../../assets/lotties/job-alert/check-done.json";
import Lottie from "react-lottie";
import { isMobile } from "react-device-detect";

function JobAlertsCreateDialog(props) {

  const { selectedCountry, isLoggedIn, triggerToast } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const screenMobile = useMediaQuery("(max-width:768px)");
  const currentCountry = process?.env?.NEXT_PUBLIC_JSW_GEOLOCATION;

  //useSelector-Start
  const experienceLevelsList = useSelector(
    (state) => state.jobs.experienceLevels
  );
  const showJobAlertCreateDialog = useSelector(
    (state) => state.dialog.showJobAlertCreateDialog
  );
  const jobTitleDropdownStatus = useSelector(
    (state) => state.user.jobTitleDropdownStatus
  );
  const jobAlertTitles = useSelector(
    (state) => state.user?.onboardingJobTitles
  );
  const searchKeywordOrigin = useSelector(
    (state) => state.dialog?.jobAlertKeywordOrigin
  );
  const jobAlertEmail = useSelector(
    (state) => state.dialog?.visitorEmailDialogInfo?.email
  );
  const searchKeyword = useSelector(
    (state) => state.dialog?.logInJobAlertDialogInfo?.keyword
  );
  //useSelector-End

  //useEffect-Start
  useEffect(() => {
    dispatch(getExperienceLevels());
  }, [dispatch]);

  useEffect(() => {
    setEmail(jobAlertEmail);
  }, [jobAlertEmail]);

  useEffect(() => {
    setKeyword(searchKeyword);
  }, [searchKeyword]);

  useEffect(() => {
    checkLightCastTokenTimeOut();
  }, []);
  //useEffect-End

  //useState-Start
  const [keyword, setKeyword] = useState(searchKeyword);
  const [email, setEmail] = useState(jobAlertEmail);
  const [selectedFrequency, setSelectedFrequency] = useState("daily");
  const [selectedMedium, setSelectedMedium] = useState("all");
  const [salary, setSalary] = useState(0);
  const [isCreated, setIsCreated] = useState(false);
  const [yearOfExperience, setYearOfExperience] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [keywordError, setKeywordError] = useState(false);
  const [keywordErrorMessage, setKeywordErrorMessage] = useState("");
  const [onHover, setOnHover] = useState(null);
  const [isFocus, setFocus] = useState(false);
  //useState-End

  // Fetching job from lightcast
  const fetchJob = _.debounce((value) => {
    dispatch(fetchingJobList(value));
  }, 300);

  const debouncedFetchJob = useCallback((value) => fetchJob(value), []);
  // Done fetching job from lightcast

  //Validations-Start
  const emailValidate = (email) => { //to check validity of email input

    if (isEmpty(email)) {

      setEmailError(true);
      setEmailErrorMessage("Required field");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {

      setEmailError(true);
      setEmailErrorMessage("Invalid email format");
    } else {

      setEmailError(false);
      setEmailErrorMessage("");
    }
  }

  const keywordValidate = (keyword) => { // to check validity of keyword input

    if (isEmpty(keyword) || keyword?.length === 0 || keyword === "") {

      setKeywordError(true);
      setKeywordErrorMessage("Required field");
    }
    else if (!keyword || (keyword && typeof keyword === "string" && keyword?.trim()?.length == 0)) {

      setKeywordError(true);
      setKeywordErrorMessage("Invalid keyword");
    }
    else setKeywordError(false);
  }

  const handleCheckInput = () => {// from previous job alert logic
    if (
      keyword.trim() === "" ||
      (email && email.toString() && email.trim() === "")
    ) {
      return false;
    } else {
      return true;
    }
  };
  //Validations-End

  //Form Inputs OnChange-Start
  const handleEmail = (event) => {

    //useState has one character delay, in order to solve this issue, prevent the use of useState for email validity checking
    const newEmail = event?.target?.value;
    setEmail(event?.target?.value);
    emailValidate(newEmail);

    if (!event?.nativeEvent?.inputType) {//if auto complete

      setEmailError(false);
    }
  }

  const onKeywordUpdate = (event) => { //using existing function but modified some parts

    //useState has one character delay, in order to solve this issue, prevent the use of useState for keyword validity checking
    const newKeyword = event?.target?.value;
    setKeyword(event?.target?.value);
    keywordValidate(newKeyword);

    if (!event?.nativeEvent?.inputType) { //if autocomplete stop validate

      dispatch(updateJobTitleSuggestionsDropdownStatus(false));
      setKeywordError(false);
      return;
    }

    dispatch(
      updateJobAlertCreateDialogWithKeyword({
        keyword: event?.target?.value,
      })
    );

    if (keyword?.length > 2 && !jobTitleDropdownStatus) {
      dispatch(updateJobTitleSuggestionsDropdownStatus(true));
    }

    if (keyword) {
      debouncedFetchJob(event?.target?.value);
    }
  };

  const onSelectJobTitle = (suggestedJobTitle) => {

    setKeyword(suggestedJobTitle);
    dispatch(updateJobTitleSuggestionsDropdownStatus(false));
  };

  const handleButtonChange = (buttonValue, type) => {

    type === "frequency" ? setSelectedFrequency(buttonValue) : setSelectedMedium(buttonValue);

    if (type === "medium" && (selectedMedium === "in-app" || selectedMedium === "both"))
      dispatch(updateJobAlertsDownloadAppDialog(true));
  }

  const handleCheckbox = (event) => {

    if (event.target?.checked) {
      setYearOfExperience([...yearOfExperience, parseInt(event.target?.name)])
    }
    else
      setYearOfExperience(yearOfExperience.filter((name) => parseInt(name) !== parseInt(event.target?.name)))
  }

  const handleSlider = (event) => {

    const newValue = parseInt(event?.target?.value);
    //0-5000 interval = 200
    //5000-10000 interval = 500
    //10000 -25000 = 1000
    const step = newValue < 5000
      ? 200
      : newValue >= 5000 && newValue < 10000
        ? 500
        : 1000;

    const roundedValue = Math.round(newValue / step) * step;

    setSalary(roundedValue);
  }

  //Form Buttons OnClick-Start
  const handleCreateAlert = () => {

    //Email and keyword validation before submitting
    emailValidate(email);
    keywordValidate(keyword);

    //if email or keyword is not valid then return nothing.
    if ((emailError && !isLoggedIn) || keywordError) return;

    if (isEmpty(selectedFrequency) && isLoggedIn) {
      triggerToast("Please select frequency to create job alert", false);
      return;
    }

    if (isEmpty(selectedMedium) && isLoggedIn) {
      triggerToast("Please select medium to create job alert", false);
      return;
    }

    if (isEmpty(keyword)) return; //double checking

    let params, result;

    const checkInputPassed = handleCheckInput();

    //Visitor Handle Create Job Alert-Start
    // Triggering api here
    if (checkInputPassed && !isLoggedIn) {
      let params = {
        email: email.trim(),
        title: keyword.trim(),
        yearOfExperience: yearOfExperience ?? [],
        salary: salary,
      };

      result = dispatch(visitorAddJobAlert(params)).then((response) => {
        if (response?.type === types.VISITOR_ADD_JOB_ALERT_SUCCEED) {
          setIsCreated(true);
          dispatch(updateShowJobAlertDialog(false));
          // dispatch(updateShowJobAlertSuccessDialog(true));
          dispatch(
            updateVisitorJobAlertDialogDetails({
              keyword: "",
            })
          );
          triggerToast("Job alert created", true);

          if (searchKeywordOrigin == "job-detail") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "job_detail",
              "job-alert-id": response.jobAlert?.id,
              "job-alert-frequency": response.jobAlert?.frequency,
              "job-alert-medium": response.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword.trim(),
            });
          } else if (searchKeywordOrigin == "job-alert-banner") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "job_list",
              "job-alert-id": response.jobAlert?.id,
              "job-alert-frequency": response.jobAlert?.frequency,
              "job-alert-medium": response.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword.trim(),
            });
          } else if (searchKeywordOrigin == "single-job-page") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "individual_job_page",
              "job-alert-id": response.jobAlert?.id,
              "job-alert-frequency": response.jobAlert?.frequency,
              "job-alert-medium": response.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword.trim(),
            });
          }
        } else if (
          response?.type === types.VISITOR_ADD_JOB_ALERT_FAILED &&
          response.error
        ) {
          const errorResponse = response.error.toString().trim();
          triggerToast(String(errorResponse), false);
        }
      });
    }
    //Visitor Handle Create Job Alert-End

    //Logged In user Handle Create Job Alert-Start
    if (isLoggedIn) {
      params = {
        title: keyword.trim(),
        frequency: selectedFrequency,
        medium: selectedMedium,
        yearOfExperience: yearOfExperience ?? [],
        salary: salary,
        useFullUrl: true,
        country: selectedCountry ? selectedCountry : currentCountry,
      };
      dispatch(createJobAlert(params)).then((response) => {
        if (response.type === types.CREATE_JOB_ALERT_SUCCEED) {
          // Custom events for GTM tracking
          if (searchKeywordOrigin == "user-profile") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "profile",
              "job-alert-id": response?.jobAlert?.id,
              "job-alert-frequency": response?.jobAlert?.frequency,
              "job-alert-medium": response?.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword,
            });
          } else if (searchKeywordOrigin == "search-suggestion") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "recent_search",
              "job-alert-id": response?.jobAlert?.id,
              "job-alert-frequency": response?.jobAlert?.frequency,
              "job-alert-medium": response?.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword,
            });
          } else if (searchKeywordOrigin == "job-detail") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "job_detail",
              "job-alert-id": response?.jobAlert?.id,
              "job-alert-frequency": response?.jobAlert?.frequency,
              "job-alert-medium": response?.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword,
            });
          } else if (searchKeywordOrigin == "job-alert-banner") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "job_list",
              "job-alert-id": response?.jobAlert?.id,
              "job-alert-frequency": response?.jobAlert?.frequency,
              "job-alert-medium": response?.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword,
            });
          } else if (searchKeywordOrigin == "single-job-page") {
            sendTrackingEvent({
              event: "CE_create_job_alert",
              origin: "individual_job_page",
              "job-alert-id": response?.jobAlert?.id,
              "job-alert-frequency": response?.jobAlert?.frequency,
              "job-alert-medium": response?.jobAlert?.medium,
              "yoe_ids": response.jobAlert?.yearOfExperience.sort() ?? [],
              "min-salary": response.jobAlert?.salary,
              keyword: keyword,
            });
          }
          if (router.pathname.includes("/jobs")) {
            triggerToast("Job alert created", true);
          } else {

            triggerToast("Job alert created", true);
          }
        } else if (response.type === types.JOB_ALERT_EXISTS) {
          triggerToast("Job alert already exists", false);
        } else {
          triggerToast("Job alert cannot be created", false);
        }
      });
    }
    //Logged In user Handle Create Job Alert-End

    if (isLoggedIn) { //Close logged in user create job alert dialog after created the job alert

      handleClose();
    }
  };

  const handleSignup = () => {

    handleClose();
    dispatch(updateSignUpOrigin("jobalert")).then(() => {
      dispatch(updateSignInModalSignUpStatus(true));
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  const handleClose = () => { //assign default value when user closes the dialog

    if (isCreated && !isLoggedIn) {

      setEmail("");
      setIsCreated(false);
    }

    setSelectedFrequency("daily");
    setSelectedMedium("all");
    setKeyword("");
    setYearOfExperience([]);
    setSalary(0);
    setEmailError(false);
    setEmailErrorMessage("");
    setKeywordError(false);

    //to let keyword remain at the keyword input after reopen the dialog
    dispatch(
      updateJobAlertCreateDialogWithKeyword({
        keyword: jobAlertTitles ?? "",
        origin: searchKeywordOrigin,
      })
    );

    dispatch(updateJobAlertCreateDialog(false));
  };
  //Form Buttons OnClick-End

  return (
    <StyledDialog
      open={showJobAlertCreateDialog}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          event.stopPropagation();
          handleCreateAlert();
        },
      }}
    >
      <StyledDialogContent>
        <StyledContentTop>
          <TitleContainer>
            <TitleContent>
              <TopContentTitle
                isMobile={screenMobile}
                sx={{ color: "var(--Text-Light-Theme-High-Emphasis, rgba(0, 0, 0, 0.87))" }}
              >
                {!isCreated ?
                  (screenMobile ? "Create new job alert" : "Get alerted with new jobs")
                  :
                  "Job alert created!"
                }
              </TopContentTitle>
              {!isCreated && (
                <TopContentSubtitle
                  isMobile={screenMobile}
                  sx={{ color: "var(--Text-Light-Theme-Medium-Emphasis, rgba(0, 0, 0, 0.60))" }}
                >
                  Never miss out, be the first to receive matches!
                </TopContentSubtitle>
              )}
            </TitleContent>
            <CloseIcon onClick={handleClose} />
          </TitleContainer>
        </StyledContentTop>
        {!isCreated ? (
          <BodyContainer>
            <BodyContent>
              {!isLoggedIn &&
                <TextFieldContainer>
                  <BodyContentTitle>
                    Email*
                  </BodyContentTitle>
                  <StyledTextField
                    isError={emailError}
                    isMobile={screenMobile}
                    onChange={handleEmail}
                    value={email}
                    name="email"
                    variant="outlined"
                    placeholder="Eg. John@gmail.com"
                    error={emailError}
                    helperText={emailError ? emailErrorMessage : ""}
                    InputProps={{
                      endAdornment: emailError && (
                        <ErrorIconContainer position="end">
                          <StyledErrorIcon />
                        </ErrorIconContainer>
                      ),
                    }}
                  />
                </TextFieldContainer>
              }
              <TextFieldContainer>
                <BodyContentTitle required error isMobile={screenMobile} helperText="Required field">
                  Keyword*
                </BodyContentTitle>
                <StyledTextField
                  isError={keywordError}
                  isMobile={screenMobile}
                  onChange={onKeywordUpdate}
                  onFocus={() => { setFocus(true) }}
                  onBlur={() => {
                    setTimeout(() => {
                      setFocus(false);
                    }, 1000);
                  }}
                  value={keyword}
                  name="keyword"
                  variant="outlined"
                  placeholder="Eg. Data analyst"
                  error={keywordError}
                  helperText={keywordError && keywordErrorMessage}
                  InputProps={{
                    endAdornment: keywordError && (
                      <ErrorIconContainer position="end">
                        <StyledErrorIcon />
                      </ErrorIconContainer>
                    ),
                  }}
                />
                {keyword?.length >= 3 && jobTitleDropdownStatus && !keywordError && isFocus && (
                  <DropdownListContainer>
                    {jobAlertTitles.slice(0, 5).map((item, index) => {
                      return (
                        <DropdownItem
                          key={`${item}-${index}`}
                          onClick={() => onSelectJobTitle(item)}
                        >
                          <DropdownText className="dropdown">{item}</DropdownText>
                        </DropdownItem>
                      );
                    })}
                  </DropdownListContainer>
                )}
              </TextFieldContainer>
              {isLoggedIn &&
                <AlertContainer>
                  <BodyContentTitle isMobile={screenMobile}>
                    Alert settings*
                  </BodyContentTitle>
                  <AlertContent>
                    <FrequencyContainer>
                      <BodyContentSubtitle isMobile={screenMobile}>
                        Select frequency:
                      </BodyContentSubtitle>
                      <ButtonGroup>
                        <StyledButton type="button" active={selectedFrequency === "daily"} onClick={() => handleButtonChange("daily", "frequency")}>
                          <ButtonText isMobile={screenMobile} active={selectedFrequency === "daily"}>Daily</ButtonText>
                        </StyledButton>
                        <StyledButton type="button" active={selectedFrequency === "weekly"} onClick={() => handleButtonChange("weekly", "frequency")}>
                          <ButtonText isMobile={screenMobile} active={selectedFrequency === "weekly"}>Weekly</ButtonText>
                        </StyledButton>
                      </ButtonGroup>
                    </FrequencyContainer>
                    <AlertViaContainer>
                      <BodyContentSubtitle isMobile={screenMobile}>
                        Alert via:
                      </BodyContentSubtitle>
                      <ButtonGroup>
                        <StyledButton type="button" active={selectedMedium === "email"} onClick={() => handleButtonChange("email", "alert-via")}>
                          <ButtonText isMobile={screenMobile} active={selectedMedium === "email"}>Email</ButtonText>
                        </StyledButton>
                        <StyledButton type="button" active={selectedMedium === "notification"} onClick={() => handleButtonChange("notification", "alert-via")}>
                          <ButtonText variant="body2" isMobile={screenMobile} active={selectedMedium === "notification"}>In-app notifications</ButtonText>
                        </StyledButton>
                        <StyledButton type="button" active={selectedMedium === "all"} onClick={() => handleButtonChange("all", "alert-via")}>
                          <ButtonText isMobile={screenMobile} active={selectedMedium === "all"}>Both</ButtonText>
                        </StyledButton>
                      </ButtonGroup>
                    </AlertViaContainer>
                  </AlertContent>
                </AlertContainer>
              }
              <StyledLine />
              <AdditionalFilterContainer>
                <BodyContentTitle isMobile={screenMobile}>
                  Additional filters
                </BodyContentTitle>
                <YOEContainer>
                  <BodyContentSubtitle isMobile={screenMobile}>
                    Years of experience:
                  </BodyContentSubtitle>
                  <CheckboxGroup>
                    {experienceLevelsList.map((yoe, index) => (
                      <CheckboxContainer>
                        <StyledFormControlLabel
                          onMouseEnter={() => { setOnHover(index) }}
                          onMouseLeave={() => { setOnHover(null) }}
                          key={yoe.id}
                          control={
                            <StyledCheckbox name={yoe.id}
                              icon={
                                <UncheckedIcon sx={{ border: onHover === index && "1px solid #222222 !important" }}>
                                  {onHover === index && <UncheckStyled />}
                                </UncheckedIcon>
                              }
                              checkedIcon={
                                <CheckedIcon>
                                  <CheckStyled />
                                </CheckedIcon>
                              }
                              onChange={handleCheckbox}
                            />
                          }
                          labelPlacement={screenMobile ? "start" : "end"}
                          label={yoe.title}
                          isMobile={screenMobile}
                        />
                      </CheckboxContainer>
                    ))}
                  </CheckboxGroup>
                </YOEContainer>
                <SalaryContainer>
                  <BodyContentSubtitle isMobile={screenMobile}>
                    Minimum monthly salary:
                  </BodyContentSubtitle>
                  <SliderContainer>
                    <StyledSlider value={salary} onChange={handleSlider} min={0} max={25000} step={salary < 5000 ? 200 : salary >= 5000 && salary < 10000 ? 500 : 1000} />
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)", lineHeight: "171.429%", alignSelf: "stretch" }}
                    >
                      {
                      isEmpty(selectedCountry)
                        ? (currentCountry === "my" ? `RM ${salary}` : `${salary} SGD`)
                        : (selectedCountry === "my" ? `RM ${salary}` : `${salary} SGD`)
                      }
                    </Typography>
                  </SliderContainer>
                </SalaryContainer>
              </AdditionalFilterContainer>
            </BodyContent>
          </BodyContainer>
        )
          :
          (
            <VisitorCreatedBodyContainer>
              <Lottie
                style={{
                  width: screenMobile ? "120px" : "160px",
                  height: screenMobile ? "120px" : "160px",
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: DoneGif,
                }}
              />
              <CreatedBodyContentText isMobile={screenMobile} >We will notify you via email when there’s new jobs available!</CreatedBodyContentText>
              <CreatedBodyContentSubtitleContainer>
                <TextContainer>
                  <CreatedBodyContentSubtitleText bold={true} sx={{ display: "inline" }}>Sign up </CreatedBodyContentSubtitleText>
                  <CreatedBodyContentSubtitleText sx={{ display: "inline" }}>or </CreatedBodyContentSubtitleText>
                  <CreatedBodyContentSubtitleText bold={true} sx={{ display: "inline" }}>log into </CreatedBodyContentSubtitleText>
                  <CreatedBodyContentSubtitleText sx={{ display: "inline" }}>your account to modify your job alert.</CreatedBodyContentSubtitleText>
                </TextContainer>
              </CreatedBodyContentSubtitleContainer>
            </VisitorCreatedBodyContainer>
          )
        }
      </StyledDialogContent>
      {isCreated && !isLoggedIn ?
        (
          <ButtonContainer>
            <VisitorButton side={"left"} onClick={handleClose}>
              <VisitorButtonText side={"left"} isMobile={screenMobile}>Done</VisitorButtonText>
            </VisitorButton>
            <VisitorButton side={"right"} onClick={handleSignup}>
              <VisitorButtonText side={"right"} isMobile={screenMobile}>Sign Up</VisitorButtonText>
            </VisitorButton>
          </ButtonContainer>
        )
        :
        (
          <ButtonContainer>
            <CreateAlertButton type="submit">
              <CreateAlertButtonText isMobile={screenMobile}>Create Alert</CreateAlertButtonText>
            </CreateAlertButton>
          </ButtonContainer>
        )}
    </StyledDialog>
  );
}

export default JobAlertsCreateDialog;
